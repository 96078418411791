/* You can add global styles to this file, and also import other style files */

.bdc-walk-popup .mat-stroked-button
    color: #3f51b5 !important

.bdc-walk-popup .header
    color: #3f51b5 !important


.shepherd-button
  background: #3f51b5 !important
  border: 0
  border-radius: 3px
  color: #ffffffbf
  cursor: pointer
  margin-right: 0.5rem
  padding: 0.5rem 1.5rem
  transition: all .5s ease

.shepherd-button:hover
    background: #5560a9 !important

.shepherd-has-title .shepherd-content .shepherd-header
    background: #fff !important
    padding: 1em !important

.shepherd-title
    font: bold 20px Roboto,Helvetica Neue,sans-serif !important



::-webkit-scrollbar
  width: 4px
  height: 8px


::-webkit-scrollbar-track
  background: #f1f1f161


::-webkit-scrollbar-thumb
  background: #276ba0
  border-radius: 10px


::-webkit-scrollbar-thumb:hover
  background: #555


mat-form-field.mat-form-field.mat-form-field-appearance-outline > div.mat-form-field-wrapper > div.mat-form-field-flex > div.mat-form-field-infix
  padding: 0.4em 0px


mat-form-field.mat-form-field.mat-form-field-appearance-outline > div.mat-form-field-wrapper > div.mat-form-field-flex > div.mat-form-field-infix > span.mat-form-field-label-wrapper
  top: -1.4em


.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label
  transform: translateY(-1.1em) scale(.75)
  width: 133.33333%


.mat-tab-label-container
  .mat-tab-list
    .mat-ink-bar
      background-color: #0082c0

.mat-icon.mat-primary
    color: #0082c0 !important

.mat-drawer-container
    height: 100% !important
    background-color: #fff !important
   
  

.mat-badge-content
  background-color: #0082c0 !important
  color: white !important



